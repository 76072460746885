<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%" height="100%" class="px-1">

            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="headline text-start">
                    세일러북
                    <span class="title ml-2">{{ price.format() }}원</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="secondary" rounded @click="showSearch = !showSearch">
                        <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                    </v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="py-0">
                        <v-row align="center">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="시작일자" placeholder=" " hide-details>
                                </v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="종료일자" placeholder=" " hide-details>
                                </v-date-field>
                            </v-col>
                        </v-row>
                        <v-row align="center" class="mt-4">
                            <v-col cols="12" sm="3" class="py-0">
                                <v-select v-model="filter.searchKey" label="검색조건" placeholder=" " hide-details
                                    :items="searchKeys" item-text="text" item-value="value"
                                    @input="filter.searchValue = null"></v-select>
                            </v-col>
                            <v-col cols="12" sm="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" placeholder=" " hide-details
                                    :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search">
                            <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-spacer />
                <v-btn color="white" class="green--text px-2 mr-3" @click="excel">
                    <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드
                </v-btn>
            </v-row>

            <v-data-table :headers="headers" :items="issues" disable-sort disable-pagination hide-default-footer
                class="elevation-1 mt-4">
                <template v-slot:item.createDate="{ item }">
                    {{ item.createDate.toDateTime() }}
                </template>
                <template v-slot:item.remark="{ item }">
                    <v-row justify-center>
                        <v-col class="pa-0">
                            <v-text-field v-model="item.remark" hide-details></v-text-field>
                        </v-col>
                        <v-col md="auto" class="pt-2 pb-2 pl-2 pr-0">
                            <v-btn @click="save(item)" color="primary" hide-detailse dense outlined>수정</v-btn>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.user="{ item }">
                    <span v-if="item.user">
                        {{ item.user.name }}
                        <span v-if="item.user.phone">({{ item.user.phone.phoneNumberFormat() }})</span>
                    </span>
                </template>
                <template v-slot:item.price="{ item }">
                    {{ item.price.format() }}원
                </template>
                <template v-slot:item.remain="{ item }">
                    {{ item.remain.format() }}원
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" class="mt-4 mb-12" total-visible="9" @input="search"></v-pagination>
            <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn bottom color="pink" dark fab fixed right @click="create">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="subtitle-2 font-weight-bold">거래장부 입력</v-card-title>
                    <v-divider />
                    <v-card-text class="pb-5 pt-5">
                        <v-text-field 
                            label="금액(원)" 
                            placeholder=" "
                            :value="editItem.price.format()"
                            @input="value => editItem.price = parseInt(+value.replace(/[^0-9\.\-]/g, '') || 0)"
                            hide-details
                            class="align-end-field">
                        </v-text-field>
                        <v-textarea v-model="editItem.remark" label="비고" placeholder=" " hide-details class="mt-4">
                        </v-textarea>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="grey darken-1" text @click="close">취소</v-btn>
                        <v-btn color="primary darken-1" text @click="save(editItem)">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from 'xlsx';
import VDateField from "@/components/common/vuetify/v-date-field.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        VDateField
    },
    data() {
        return {
            mdiMicrosoftExcel,

            showSearch: true,
            dialog: false,

            filter: {
                createdAt: [this.$route.query?.createdAt?.[0] || '', this.$route.query?.createdAt?.[1] || ''],
                partner: "nanuri",
                searchKey: null,
                searchValue: null,
            },

            page: 1,
            pageCount: 0,
            limit: 20,
            
            issues: [],
            headers: [
                { text: '날짜', align: 'center', width: 180, value: 'createDate' },
                { text: '앱 사용자', align: 'center', width: 200, value: 'user' },
                { text: '세일러북 아이디', align: 'start', width: 180, value: 'data1' },
                { text: '비고', align: 'center', value: 'remark' },
                { text: '금액', align: 'end', width: 180, value: 'price' },
                { text: '잔액', align: 'end', width: 180, value: 'remain' },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "이름", value: "name" },
                { text: "연락처", value: "phone" },
                { text: "세일러북 아이디", value: "data1" }
            ],
            
            price: 0,

            editItem: {
                partner: "nanuri",
                price: 0,
                remark: null,
            },
            defaultItem: {
                partner: "nanuri",
                price: 0,
                remark: null,
            }
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },

        async search(routable = true){
            if(routable){
                this.$router.push({
                    query: {
                        ...this.filter,
                        page: this.page
                    }
                });
            }
            var { summary, issues } = await api.console.issue.getIssues({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.issues = issues;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.price = summary.issue;
        },

        create() {
            this.editItem = Object.assign({}, this.defaultItem);
            this.dialog = true;
        },

        close() {
            this.dialog = false
        },

        // update(item){
        //     return api.console.issue.putIssue(item);

        //         this.$http.put('/api/console/issues/' + this.partner, { issues: [item] }).then((res) => {
        //             alert("수정되었습니다")
        //         }).catch((error) => {
        //             if (error.response) {
        //                 alert(error.response.data.message);
        //             }
        //         });
        // },

        async save(issue) {

            issue._id ? await api.console.issue.putIssue(issue) : await api.console.issue.postIssue(issue);
            this.search();
            this.close();

            alert("저장되었습니다");

            
                // var data = {
                //     price: parseInt(this.editItem.price),
                //     remark: this.editItem.remark
                // };

                // this.$http.post("/api/console/issues/" + this.partner, data).then((res) => {
                //     this.getIssues();
                //     this.close();
                // }).catch((error) => {
                //     if (error.response) {
                //         alert(error.response.data.message);
                //     }
                //     this.close();
                // });
        },

        excel(){
            api.console.issue.getIssues({ params: this.filter }).then(data => {
                var sheet = [];
                for(var issue of data.issues){
                    sheet.push({
                        "날짜": issue.createDate.toDateTime(),
                        "비고": issue.remark,
                        "앱 사용자": issue.user ? `${issue.user.name}(${this.issue?.user?.phone?.phoneNumberFormat()})` : '',
                        "세일러북 아이디": issue.data1,
                        "금액": parseInt(issue.price || 0).format(),
                        "잔액": parseInt(issue.remain || 0).format()
                    });
                }
                var workbook = XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(sheet);

                XLSX.utils.book_append_sheet(workbook, worksheet, "모두의 애드 앱 이용내역");
                XLSX.writeFile(workbook, "모두의 애드 앱 이용내역.xlsx");
            });
        },
    }
}
</script>

<style>
.align-end-field input { text-align: right; }
.align-end-field .v-messages__message { text-align: right; }
</style>
